import * as React from "react"
import { Link, navigate } from "gatsby"
import { useEffect } from "react"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const NotFoundPage = () => {
  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 1000);
  }, []);

  return (
    <main style={pageStyles}>
      <h1>404</h1>
    </main>
  )
}

export default NotFoundPage
